'use client';

import React, { FC } from 'react';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import { Icons } from '../icons';

interface IProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
  width?: string;
}

export const Modal: FC<IProps> = ({
  open,
  onClose,
  title,
  children,
  width,
}) => {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className={`${width} relative overflow-hidden rounded-lg bg-white px-4 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
          >
            <DialogTitle className="flex items-center justify-between  px-4 pb-4 pt-5 sm:p-6 sm:pb-4 ">
              <h3 className="font-bold">{title}</h3>
              <span onClick={onClose} className="cursor-pointer">
                <Icons.close />
              </span>
            </DialogTitle>
            <div>{children}</div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
