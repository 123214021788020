'use client';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { Form, FormControl, FormField, FormItem } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
// import { useToast } from '@/components/ui/use-toast';
import { cn } from '@/lib/utils';
import style from './FreeQuoteForm.module.scss';
import Button from '@/components/ui/Button/Button';
import InputMask from 'react-input-mask';
import { sendForm } from '@/app/api/forms/sendForm';
import toast from 'react-hot-toast';

const formSchema = z.object({
  full_name: z.string().min(3),
  phone: z.string().min(3),
  email: z.string().email(),
  message: z.string().min(3),
});

const defaultValues = {
  full_name: '',
  phone: '',
  email: '',
  message: '',
};

type FormSchema = z.infer<typeof formSchema>;

interface Props {
  onClose?: () => void;
}

export const FreeQuoteForm = ({ onClose }: Props) => {
  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });
  // const { toast } = useToast();

  const onSubmit = async (data: FormSchema) => {
    try {
      await sendForm(data, { form_type: 'Free Quote' });

      form.reset(defaultValues);
      onClose?.();

      toast.success('The form successfully submitted');
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong');
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={style.form}
        noValidate
      >
        <p className="mb-4 text-center text-[36px] font-semibold leading-[46px]">
          {' '}
          Get a Free Quote!
        </p>
        <p className="text-center">Need a service?</p>
        <p className="mb-[32px] text-center">
          {' '}
          Let’s talk about how Tytum can meet and exceed your expectations.
        </p>
        <div className="mb-1 w-full">
          {' '}
          <FormField
            control={form.control}
            name="full_name"
            render={({ field }) => (
              <FormItem>
                {/*  <FormLabel className={style.label} htmlFor="name">
                  {' '}
                  Name
                </FormLabel> */}
                <FormControl>
                  <Input
                    placeholder={'Enter your name'}
                    className={cn(
                      form.formState.errors.full_name && 'border-destructive',
                      'bg-white',
                      style.input
                    )}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
        <div className="mb-1 w-full">
          {' '}
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                {/* <FormLabel className={style.label} htmlFor="phone">
                  {' '}
                  Phone
                </FormLabel> */}

                <FormControl>
                  <InputMask mask="(999) 999-9999" {...field}>
                    <Input
                      placeholder={'Enter your phone number'}
                      className={cn(
                        form.formState.errors.phone && 'border-destructive',
                        'bg-white',
                        style.input
                      )}
                    />
                  </InputMask>
                </FormControl>
              </FormItem>
            )}
          />
        </div>
        <div className="mb-1 w-full">
          {' '}
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                {/*  <FormLabel className={style.label} htmlFor="email">
                  {' '}
                  Email
                </FormLabel> */}

                <FormControl>
                  <Input
                    placeholder={'Enter your email'}
                    className={cn(
                      form.formState.errors.email && 'border-destructive',
                      'bg-white',
                      style.input
                    )}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>

        <div className="mb-1 w-full">
          {' '}
          <FormField
            control={form.control}
            name="message"
            render={({ field }) => (
              <FormItem>
                {/*  <FormLabel className={style.label} htmlFor="message">
                  {' '}
                  Message
                </FormLabel> */}
                <FormControl style={{ width: '100%' }}>
                  <Textarea
                    style={{ minHeight: '150px' }}
                    placeholder={'Write your message'}
                    className={cn(
                      'md:w-96',
                      form.formState.errors.full_name && 'border-destructive',
                      'bg-white',
                      'w-full',
                      style.input
                    )}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
        <div className="text-center">
          <Button variant="main" shape="square" type="submit">
            <p className="text-center text-[18px]">Get a Free Quote!</p>
          </Button>
        </div>
      </form>
    </Form>
  );
};
