import React from 'react';
import Link from 'next/link';

export const SingleItem = ({ item, isActive, onClick }) => {
  return (
    <Link
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      href={item.path || '#'}
      className={`text-left ${isActive ? 'font-semibold' : ''}`}
    >
      {item.label}
    </Link>
  );
};
