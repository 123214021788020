'use client';

import { PropsWithChildren } from 'react';
import { ReCaptchaProvider } from 'next-recaptcha-v3';

import ModalsContextProvider from '@/context/ModalsContext';
import ClientOnly from '@/helpers/ClientOnly';
import { Modals } from '@/components/Modals/Modals';

function Providers<T>({ children }: PropsWithChildren<T>) {
  return (
    <ReCaptchaProvider
      language="en"
      useEnterprise={true}
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
    >
      <ModalsContextProvider>
        <ClientOnly>{children}</ClientOnly>

        <Modals />
      </ModalsContextProvider>
    </ReCaptchaProvider>
  );
}

export default Providers;
