import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-poppins\",\"fallback\":[\"system-ui\",\"arial\"],\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"]}],\"variableName\":\"fontPoppins\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/src/components/client/Container/Container.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/src/components/theme-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/client/Footer/components/FooterSocial/FooterSocial.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["MainFooterInfo"] */ "/app/src/features/client/Footer/components/MainFooterInfo/MainFooterInfo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavFooterList"] */ "/app/src/features/client/Footer/components/NavFooterList/NavFooterList.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/client/Footer/Footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/app/src/features/client/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/Providers.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
