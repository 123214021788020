export const menuItems = [
  {
    label: 'Services',
    path: '/services',
    children: [
      {
        label: 'Heating and Air',
        path: '/services/heating-and-air',
        children: [
          { label: 'Heating', path: '/services/heating-and-air/heating' },
          {
            label: 'Air Conditioning',
            path: '/services/heating-and-air/air-conditioning',
          },
          {
            label: 'Ductless Cooling & Heating',
            path: '/services/heating-and-air/cooling-and-heating',
          },
          {
            label: 'Air Conditioning Installation',
            path: '/services/heating-and-air/ac-installation',
          },
          {
            label: 'Heating System Installation',
            path: '/services/heating-and-air/heating-installation',
          },
        ],
      },
      { label: 'Electrical', path: '/services/electrical' },
      { label: 'Plumbing', path: '/services/plumbing' },
      { label: 'Remodeling', path: '/services/remodeling' },
      { label: 'New Construction', path: '/services/construction' },
      { label: 'Real Estate Brokerage', path: '/services/brokerage' },
    ],
  },
  { label: 'Rebates', path: '/rebates', children: [] },
  { label: 'Testimonials', path: '/testimonials', children: [] },
  { label: 'Partners', path: '/partners', children: [] },
  {
    label: 'About Us',
    path: '/company/about',
    children: [
      { label: 'Contact Us', path: '/company/contact' },
      // { label: 'About Us', path: '/company/about' },
      { label: 'FAQ', path: '/company/faq' },
      { label: 'Careers', path: '/company/careers' },
    ],
  },
  /*  {
    label: 'Contact',
    path: '/company/contact',
    children: [],
  }, */
];
