'use client';
import React from 'react';
import style from './MainFooterInfo.module.scss';
import { Icons } from '@/components/icons';
import { Button } from '@/components/ui/button';
import { useModalContext } from '@/context/ModalsContext';

export const MainFooterInfo = () => {
  const { setisModalCallsOpen } = useModalContext();
  return (
    <div className={style.container}>
      <div>
        <Icons.logoWhite />
        <p className="text-white text-left mt-8">Home Solutions with Tytum</p>
      </div>
      <div className="mt-10 text-left">
        <p className="text-white text-left mb-5">Need Urgent Service?</p>
        <Button
          onClick={() => setisModalCallsOpen(true)}
          className=" flex gap-2 bg-textOrange text-white text-white py-2 px-5 rounded-full text-base hover:opacity-75"
        >
          <Icons.phoneIcon /> <p>Call Us</p>
        </Button>
      </div>
    </div>
  );
};
