'use client';
import React, { useState, useRef, useEffect } from 'react';
import { usePathname } from 'next/navigation'; // Для получения текущего пути
import style from './NavItem.module.scss';
import { SingleItem } from './components/SingleItem';
import { Icons } from '@/components/icons';
import { cn } from '@/lib/utils';
import Link from 'next/link';

interface MenuItem {
  label: string;
  url?: string;
  children?: MenuItem[];
}

interface NavItemProps {
  items: MenuItem;
}

export const NavItem: React.FC<NavItemProps> = ({ items }) => {
  const ref = useRef<HTMLUListElement>(null);
  const listref = useRef<HTMLUListElement>(null);
  const pathname = usePathname();
  const [openMenus, setOpenMenus] = useState<Record<string, boolean>>({});

  const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | null>(null);

  const toggleMenu = (key: string, value?: boolean) => {
    setOpenMenus((prev) => ({
      ...prev,
      [key]: value !== undefined ? value : !prev[key],
    }));
  };

  const isItemActive = (item: MenuItem): boolean => {
    if (item.path && pathname.startsWith(item.path)) {
      return true;
    }

    if (item.children) {
      return item.children.some((child) => isItemActive(child));
    }
    return false;
  };

  useEffect(() => {
    setOpenMenus({});
  }, [pathname]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (event.target.className === 'text-left ') return;
      setTimeout(() => setOpenMenus({}), 100);
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const handleMouseEnter = (key: string) => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
    toggleMenu(key, true);
  };

  const handleMouseLeave = (key: string) => {
    const timeout = setTimeout(() => {
      toggleMenu(key, false);
    }, 300);
    setHoverTimeout(timeout);
  };

  const renderItems = (menuItems, level = 0) => {
    return (
      <ul className={style.navItemsList}>
        {menuItems.map((item, index) => {
          const key = `${level}-${index}`;
          const hasChildren = item.children && item.children.length > 0;

          const isActive = isItemActive(item);

          return (
            <li
              key={key}
              className={cn(
                `relative ${style.navItem}`,
                isActive && level === 0
                  ? 'border border-black rounded-[4px]'
                  : 'border border-transparent'
              )}
              onMouseEnter={() => handleMouseEnter(key)}
              onMouseLeave={
                hasChildren && level === 1 ? () => handleMouseLeave(key) : null
              }
            >
              {hasChildren ? (
                <>
                  <button
                    className={cn(`flex items-center w-full  text-left `)}
                  >
                    <SingleItem item={item} />
                    <Icons.arrowDown isOpen={openMenus[key]} />
                  </button>
                  {openMenus[key] && (
                    <ul ref={ref}>{renderItems(item.children, level + 1)}</ul>
                  )}
                </>
              ) : (
                <SingleItem item={item} />
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  return <div ref={listref}>{renderItems([items])}</div>;
};
