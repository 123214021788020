'use client';

import { useModalContext } from '@/context/ModalsContext';
import { Modal } from '../Modal/Modal';
import { RequestFormMain } from '@/features/client/Forms/RequestFormMain/RequestFormMain';
import { FreeQuoteForm } from '@/features/client/Forms/FreeQuoteForm/FreeQuoteForm';
import { CallUsForm } from '@/features/client/Forms/CallUsForm/CallUsForm';

export function Modals() {
  const {
    isModalOpened,
    setIsModalOpened,
    isModalQuoteOpen,
    setisModalQuoteOpen,
    isModalCallsOpen,
    setisModalCallsOpen,
  } = useModalContext();
  if (isModalOpened) {
    return (
      <Modal
        title=""
        open={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        width="w-[1100px]"
      >
        <RequestFormMain onClose={() => setIsModalOpened(false)} />
      </Modal>
    );
  }
  if (isModalQuoteOpen) {
    return (
      <Modal
        title=""
        open={isModalQuoteOpen}
        onClose={() => setisModalQuoteOpen(false)}
        width="w-[700px]"
      >
        <FreeQuoteForm onClose={() => setisModalQuoteOpen(false)} />
      </Modal>
    );
  }
  if (isModalCallsOpen) {
    return (
      <Modal
        title=""
        open={isModalCallsOpen}
        onClose={() => setisModalCallsOpen(false)}
        width="w-[700px]"
      >
        <CallUsForm />
      </Modal>
    );
  }
  return null;
}
