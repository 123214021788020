'use client';
import React, { useState } from 'react';
import style from './NavFooterList.module.scss';
import Link from 'next/link';
import { Icons } from '@/components/icons';
import { cn } from '@/lib/utils';

const renderLinkWithType = (item) => {
  if (item.type === 'phone')
    return (
      <a onClick={(e) => e.stopPropagation()} href={`tel:${item.path}`}>
        {item.title}
      </a>
    );
  if (item.type === 'email')
    return (
      <a onClick={(e) => e.stopPropagation()} href={`mailto:${item.path}`}>
        {item.title}
      </a>
    );
  if (item.type === 'location')
    return (
      <a
        target="_blank"
        onClick={(e) => e.stopPropagation()}
        href={`${item.path}`}
      >
        {item.title}
      </a>
    );
};

export const NavFooterList = ({ items }) => {
  const [openItems, setOpenItems] = useState({});

  const toggleOpen = (idx) => {
    setOpenItems((prev) => ({
      ...prev,
      [idx]: !prev[idx],
    }));
  };

  return (
    <div className={cn(style.navList)}>
      {items.map((item, idx) => (
        <div key={idx} className={style.navListInner}>
          <p className="text-white text-left text-2xl mb-6">{item.title}</p>
          {!!item?.items?.length
            ? item?.items.map((nestedItem, nestedIdx) => (
                <div key={nestedIdx}>
                  <p
                    className="flex justify-between text-white text-left mb-3 text-base hover:opacity-75 cursor-pointer"
                    onClick={() => toggleOpen(`${idx}-${nestedIdx}`)}
                  >
                    {!!nestedItem?.type ? (
                      renderLinkWithType(nestedItem)
                    ) : (
                      <Link
                        onClick={(e) => e.stopPropagation()}
                        href={nestedItem.path || '#'}
                      >
                        {nestedItem.title}
                      </Link>
                    )}

                    {!!nestedItem?.items?.length && (
                      <span>
                        <Icons.arrowDownRouded
                          rotate={
                            openItems[`${idx}-${nestedIdx}`] ? '0' : '180'
                          }
                        />
                      </span>
                    )}
                  </p>

                  <div
                    className={`${style.nestedList} ${
                      openItems[`${idx}-${nestedIdx}`] ? style.open : ''
                    }`}
                  >
                    {nestedItem?.items?.map((subItem) => (
                      <p
                        className="flex justify-between text-white text-left mb-3 text-base pl-4 hover:opacity-75"
                        key={subItem.title}
                      >
                        <Link href={subItem.path || '#'}>{subItem.title}</Link>
                      </p>
                    ))}
                  </div>
                </div>
              ))
            : null}
        </div>
      ))}
    </div>
  );
};
