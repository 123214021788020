'use client';
import { Icons } from '@/components/icons';

export const CallUsForm = () => {
  return (
    <div>
      <p className="font-poppins mb-5 text-center text-[44px] font-semibold leading-[54px]">
        Call Us Now!
      </p>
      <div className="mb-10 text-center">
        <p>
          Looking for a service or need more information? Get in touch with us
          today—we&apos;re here to help!
        </p>
      </div>
      <a
        className="font-poppins mb-10 block text-center text-[26px] font-medium leading-[28px]
"
        href="tel:+16193494397"
      >
        (619) 349 4397
      </a>
      <div className="mb-10 text-center">
        <a
          href="tel:+16193494397"
          className=" bg-textOrange inline-flex items-center gap-2 rounded-full px-5 py-2 text-base text-white hover:opacity-75"
        >
          <Icons.phoneIcon /> <p>Call Us</p>
        </a>
      </div>
    </div>
  );
};
