import React from 'react';
import { NavItem } from '../NavItem/NavItem';
import { menuItems } from '@/constants';

export const Nav = () => {
  return (
    <nav className="hidden md:flex ">
      {menuItems.map((item, idx) => (
        <NavItem items={item} key={idx} />
      ))}
    </nav>
  );
};
